.coupon-status-details-container{
	@include clearfix();
	width:100%;
	color: $brand-color-2;
    font-size: $font-size-h4;
    background:$light;
    height: 600px;
    overflow-y: scroll;
	&__header{
    margin-top: 0.4375rem;
    font-size: $font-size-h3;
    background-color: lighten($brand-color-3,8);
    padding: 0.3125rem 0.3125rem 0.3125rem 0.4375rem;
    text-transform: uppercase;
	}
	&__status-details{
		width:100%;
		&_header,&_name-header{
			color: $brand-color-2;
			text-transform: capitalize;
			padding-top: 1rem;
            padding-left: 0.3125rem;
		}
		&_name-header{
			padding-top: 0;
		}
		
	}
	
}

.coupon-status-footer{
	 background:$brand-color-23;
	&__term-and-conditions{
		padding-left: 0.3125rem;
		color:$brand-color-5;
		padding-top: 20.3125rem;
		position: absolute;
        bottom: 3.125rem;
	}
	&__button-container{
		@include clearfix();
		width:100%;
		padding-top: 0.625rem;
		position: absolute;
        bottom: 1.25rem;
        right: 1.25rem;
		> button {
			float:right;
			padding-right: 0.3125rem;
			@include button-size($base-padding/6, 0, $font-size-h3, inherit, 0);
  		    @include make-button($brand-color-3, $brand-color-2, $brand-color-3, darken($brand-color-2,20));
			min-width: 11.25rem;
			height: 1.875rem;
			padding:0;
		}
	}
}
.status-info-container{
	padding-top: 0.3125rem;
	&__header{
		@include clearfix();
		width:100%;
		 background-color: lighten($brand-color-3,8);
		  padding: 0.3125rem 0.3125rem 0.3125rem 0.4375rem;
		&_title{
			float:left;
			width:11.11%;
			text-transform: uppercase;
			
		}
	}
	&__status{
		@include clearfix();
		width:100%;
		&_value{
		    float:left;
			width:11.11%;
			color:$brand-color-5;
			padding-left: 0.3125rem;
		}
	}
}

.coupon-status-modal-container{
height:700px;
background: $brand-color-23;
}



