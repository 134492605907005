.endorsement-details-container{
  @include make-input;
  @include clearfix();
  &__message {
    height: 10rem;
    margin-top: 60px;
    margin-left: 25px;
    font-size: 0.9375rem;
    font-weight: 700;
  }
  &__done-button{
    float: right;
    > button{
	@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
	   		margin-right: 0.125rem;
	   		margin-top: 1.625rem;
			padding: 0.525rem;
			width: 250px; 
    }
}
}

.modal-open {
  .endorsement-details {
    overflow: hidden;
  }
}
.endorsement-details{
  .modal-content {
    max-width: 70%;
    margin: auto;
    margin-top: 20%;
    padding-top: 0.25rem;
  }
}
