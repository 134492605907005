.air__dubz {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-flight-row;
  &_wrapper {
    padding-top: $base-padding/2;
    @include clearfix;
    .content {
      padding-left: $base-padding/2;
      padding-right: 0;
    }
    .dubz-content {
	  @include make-three-fourth;
		
	   	&__dubz-details_wrapper{
	      width:100%;
	    }
	    &__price_wrapper {
	      @include make-one-fourth;
	      	.sidebar {
	        	width: 100%;
	        	.book-card__table {
	          	width: 100%;
	         }
	      }
	    }
     }
  }
}

.dubz-input{
	.content {
     padding-left: $base-padding/2;
     padding-right: 0;
     width: 100%;
   }
	
	&__card {
    	box-shadow: $row-shadow-color;
   		background-color: $light;

    	&_header {
      	 	text-transform: uppercase;
      		color: $brand-color-2;
     		padding-left: 0;
    	}

    .dubz-heading {
      	font-size: $font-size-h3;
      	background-color: $brand-color-3;
      		&__container {
        		padding-top: 0.3125rem;
        		height: 2.5rem;
        		padding-left: 0.9375rem;
		        background-color: $light;
		        display: inline;
    			padding-right: 0.9375rem;
    			padding-bottom: 0.3125rem;
      		}
    	}
	}
}

.dubz-details{
	content: "";
    display: table;
	width: 100%;
	
	&__img{
		width: 25%;
    	float: left;
    	padding: 0.625rem 0.938rem;
		
		&_dubz-img{
			width: 95%;
			height: 7rem;
		}
	}
	
	&__note{
		float: left;
	    width: 55%;
	    text-align: left;
	    padding-top: 1rem;
		
		&_additional-info{
			color: $brand-color-2;
		}
	}
	
	&__price{
		float: right;
	    width: 20%;
	    text-align: center;
	    padding-top: 1rem;
		
		&_header{
		   color: $brand-color-2;
		}
	}
}

.add-dubz-details{
	content: "";
    display: table;
	padding-bottom: 0.625rem;
	width: 100%;
    padding-top: 1rem;

	&__dubz-container{
		padding-left: 1rem;
		
		&_row{
			padding-top: 0.625rem;
			padding-bottom: 0.625rem;
			float: left;
    		width: 33%;
		}
	}
	
	input[type="text"] {
        width: 70%;
        padding: $base-padding/10;
        background: none;
        vertical-align: middle;
        line-height: normal;
        background-color: $input-bgcolor;
        border: 1px solid;
        border-color: $input-border-color;
        max-height: 1.25rem;
    }
    select {
        width: 70%;
        padding: 0 $base-padding/10;
        background: none;
        vertical-align: middle;
        line-height: normal;
        max-height: 1.25rem;
    }
}

 	.row{
		&_input-label{
			color:  $brand-color-2;
			padding-bottom: 0.5rem;
		}
		
		&_input-field{
			width: 80%;
			border: 1px solid $input-border-color;
		}
	}

.input-field{
	 &__country-code{
		float: left;
	    width: 17%;
	 }
	 &__mobile_num{
		float: left;
	    width: 77%;
	 }
}

.add-dubz-details-checkBox{
	content: "";
    display: table;
	padding: 1rem;
	
	&__static-note{
		padding: 0.625rem;
	}
	&__terms{
		color: $brand-color-2;
	}
}

.add-dubz-details-button {
    padding-left: 1.875rem;
    margin-bottom: 1.875rem;
    @include make-three-fourth;
	padding: 1rem;
	background-color: $light;
    border-top: 2px solid  $brand-color-2;
	width: 100%;

    &__add-dubz-button {
      @include make-one-half;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-left: 1.875rem;
      background-color: $brand-color-1;
      border: none;
      line-height: inherit;
      border-radius: 0;
      background-image: none;
      width: 25%;
      color: $light;
      font-weight: 700;
      float: right;
      &:hover {
        background-color: darken($brand-color-1, 20);
      }
	  &:disabled {
      background-color: lighten($brand-color-1, 20);
      &:hover {
        background-color: lighten($brand-color-1, 20);
      }
        cursor: not-allowed;
     }
    }
  }
  
 .dubz-fare-details{
	width: 100%;
    padding-left: 0.938rem;
    float: left;

     &__dubz-fare-row{
         float: left;
    	 width: 14.28%;

           &_fare-label{
				padding-top: 0.938rem;
				color:  $brand-color-2;
		   }

		   &_fare{
				padding-top: 0.625rem;
		   }     

	 }
  }

  .dubz-header{
	
	 height: 1.875rem;
      padding-left: 0.938rem;
      margin-right: $base-margin/8;
      font-size: 0.90rem;
      background-color: $gray-lighter;
      margin-top: 0.313rem;
      padding-top: 0.313rem;
      width: 100%;
	 
   }
