.air__ancillary {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-flight-row;
  &_wrapper {
    padding-top: $base-padding/2;
    @include clearfix;
    .content {
      padding-left: $base-padding/2;
      padding-right: 0;
    }
    .ancillary-content {
	  @include make-three-fourth;
	   	&__meal_wrapper,
	    &__baggage_wrapper {
	      width:100%;
	    }
	    &__price_wrapper {
	      @include make-one-fourth;
	      	.sidebar {
	        	width: 100%;
	        	padding-top: $base-padding/2;
	        	.book-card__table {
	          	width: 100%;
	         }
	      }
	    }
     }
  }
  &_footer {
    padding-left: 30px;
    margin-bottom: 30px;
    @include make-three-fourth;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
    .add-ancillary-button {
      @include make-one-half;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-left: 30px;
      margin-top: 30px;
      background-color: $brand-color-1;
      border: none;
      line-height: inherit;
      border-radius: 0;
      background-image: none;
      width: 27%;
      color: $light;
      font-weight: 700;
      float: right;
      &:hover {
        background-color: darken($brand-color-1, 20);
      }
    }
  }
}
