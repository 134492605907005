.flight__pax-info {
    @include create-main-wrapper;
    // @include create-row;
    @include create-row-card;
    @include create-row-card-header;
    @include create-card-content;
    &_card {
        box-shadow: $row-shadow-color;
        padding-bottom: 0.9375rem;
        &_extra {
            border-bottom: 1px solid #087dc2;
            padding-bottom: 0.9375rem;
        }
        &_header {
            text-transform: uppercase;
            color: $brand-color-2;
            padding-left: 0px;
        }
        &_info {
            color: $brand-color-1;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            padding-left: 0.2rem
        }
    }
    &_on-hold-notification{
        display: flex;
        justify-content: flex-end;
        color: $asterisk-bgcolor;
    }
    &_preferences {
        .preferences {
            &__heading {
                text-transform: uppercase;
                color: $brand-color-2;
                margin-bottom: 15px;
                width: 25%;
                &:hover{
    		    cursor: pointer;
     			text-decoration: underline;
    				   }
            }
            &__container {
                @include clearfix;
                padding-left: 200px;
                .seat-preference, .meal-preference, .ffn-preference {
                    @include clearfix;
                    padding: 7px 0px;
                    &__heading, &__options {
                        float: left;
                    }
                    &__heading {
                        @include make-two-fifth;
                        width: 20%;
                        text-align: right;
                    }
                    &__options {
                        @include make-three-fifth;
                        >select {
                            width: 45%;
                            height: 23px;
                        }
                    }
                }
                .ffn-preference__options {
                    &_container {
                        @include clearfix;
                        .ffn-options, .ffn-number {
                            float: left;
                            width: 30%;
                            margin-right: 15px;
                            padding: 7px 0px;
                            >select {
                                width: 100%;
                                height: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
    &_row {
        @include clearfix;
        border-top: 1px solid $brand-color-2;
        &:last-child {
            border-bottom: 1px solid $brand-color-2;
        }
    }
    &_header {} &_footer {
        @include clearfix;
        padding-top: .9375rem;
    }
    .footer_text {
        margin-bottom: 0;
    }
    &_button ,&_add-dubz-button {
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        background-color: $brand-color-1;
        border: none;
        line-height: inherit;
        border-radius: 0;
        background-image: none;
        width: 25%;
        color: $light;
        font-weight: 700;
        float: right;
        &:hover {
            background-color: darken($brand-color-1, 20);
        }
	    &:disabled {
   		   cursor: not-allowed;
   		   opacity: 0.65;
	       box-shadow: none;
        }
        margin-left: 0.625rem;
    }
    
    &_save-traveler-btn{
	  	float:right;
	  	@include make-save-traveler-button(25%);
   	}
    
    &_ancillary-button-block {
    	 width: 73%;
    	 padding-left: 0.85rem;
    	 padding-right: 0.85rem;
    	 float: left;
    	 padding-top: 0.9375rem;
    	 >button {
    		width: 35%;
    	 }
    }
}
.hotel__pax-info {
    @include create-pax-info;
}
.view__cart {
    float: right;
    padding: $base-padding/6;
}
.hotel__pax-info_main .content {
    padding-left: 0.9375rem;
}
.asterisk {
    color: $asterisk-bgcolor;
    font-size: $asterisk-fontsize;
    vertical-align: text-top;
}