.flight__review {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-flight-row;
  &_main {
    padding-top: $base-padding/2;
    .content {
      padding-left: $base-padding/2;
      padding-right: 0;
    }
  }

  &_card {
  
  .pnr-fare-selection{
  	&__table_price-breakup{
  		.review-fare-details_card_header{
  			margin-bottom:3%;
  		}
  	}
  	
  	&__trip_description{
  		float:left;
  	}
  	
  	&__select_btn{
  		float:right;
  		@include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
  		@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
    	width: 18%;
    	font-weight: 700;
    	text-transform: uppercase;
  	}
  	
  	&__fare-category-img-container{
  		img{
	  		height:20px;
  		}
  		
  		padding-right:5px;
  		
  	}
  }
  
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-left: 0px;
    }
    .header_text {
      margin-right: $base-margin/8;
      font-size:0.90rem;
    }
    .header_legs {
      //margin: $base-margin/2;
      //padding: 0 $base-padding/6;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 30px;
        height: 1px;
        background-color: $brand-color-2;
        left: 100%;
        top: 50%;
        margin-top: -1px;
      }
      &:last-child:after {
        content: none;
      }
    }
    @include create-review-nav;
  }
}
.review {
  @include create-flight-detail-row;
}
.details__details {
  &_layover,
  &_return {
    position: relative;
    text-align: center;
    &:after {
      position: absolute;
      width: 50%;
      height: 1px;
      top: 50%;
      margin-left:25% ;
      content: "";
    }
  }
  &_layover {
    font-family: $font-family-number;
  }
  &_return {
    color: $brand-color-2;
  }
  &_layover:after {
    background-color: lighten($brand-color-5, 20);
  }
  &_return:after {
    background-color: $brand-color-3;
  }
}

// .change__info {
//   text-align: center;
//   position: relative;
//   z-index: 99999;
//   padding-left: $base-padding/2;
//   padding-right: $base-padding/2;
//   background-color: $light;
// }
// .show__more {
//   background-color: $brand-color-4;
//   border-top: 1px solid $brand-color-3;
//   margin-top: -15px;
//   margin-bottom: 15px;
//   padding-top: 5px;
//   padding-bottom: 5px;
//   padding-left: 15px;
//   a {
//     color: $dark;
//   }
// }
// .more__result {
//   margin-top: -15px;
// }
hr.legs-seperator {
  position: absolute;
  /* display: inline; */
  /* float: left; */
  width: 110px;
  height: 1px;
  background-color: red;
  top: 0;
  left: 50%;
  z-index: -1;
  margin-left: -55px;
}