.ancillary-select {
  @include create-main-wrapper;

  &_main {
    padding-top: $base-padding/2;

    .content {
      padding-left: $base-padding/2;
      padding-right: 0;
    }
  }

  .ancillary-content {
    @include make-three-fourth;

    &__meal_wrapper,
    &__baggage_wrapper,
    &__seat_wrapper {
      width: 100%;
    }

    &__price_wrapper {
      @include make-one-fourth;

      .sidebar {
        width: 100%;
        padding-top: $base-padding/2;

        .book-card__table {
          width: 100%;
        }
      }
    }
  }
}