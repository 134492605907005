%rule-title {
	padding: $base-padding/6;
	font-weight: bold;
	font-size: $font-size-h5;
	text-transform: uppercase;
	background-color: $brand-color-2;
	color: $light;
	box-sizing: border-box;
	float: left;
	&:not(:last-child) {
		border-right: 2px solid;
	}
}

%fare-rule-text {
	padding: $base-padding/6;
	font-size: $font-size-h5;
}

%fare-rule-header {
	font-weight: bold;
	background-color: $light;
	float: left;
	color: $brand-color-19;
	font-weight: 500;
	font-family: $font-family-base;
	&:not(:last-child) {
		border-right: 3px solid $brand-color-4;
	}
}

.fare-rule-table{
	width:100%;
	text-align: left;
}

.rule-header{
	&__row{
		width: 100%;
		margin-bottom: $base-padding/3;
		@include clearfix;
	}
	&__title{
		@extend %rule-title;
		width: 40%;
	}
	&__sub-title{
		&_column-large{
			@extend %rule-title;
			width: 60%;
		}
		&_column-medium{
			@extend %rule-title;
			width: 30%;
		}
		&_column-small{
			@extend %rule-title;
			width: 20%;
		}
	}
}
.rule-table{
	background-color: $brand-color-4;
	margin-bottom: $base-padding/6;
	border-radius: $base-padding/3 $base-padding/3 0 0;
	word-wrap: break-word;
	&__row{
		width: 100%;
		text-transform: uppercase;
		padding: 0 $base-padding/6 $base-padding/6 $base-padding/6;
		display: flex;

		&_rule-text{
			width:auto;
			@extend %fare-rule-text;
			color:$gray;
			background-color: lighten($brand-color-3,8);
			sup{
				color:$brand-color-1;
			}
		}
		&_rule-header{
			@extend %fare-rule-text;
			@extend %fare-rule-header;
		}
		&_rule-header-large{
			width: 60%;
		}
		&_rule-header-medium{
			width: 30%;
		}
		&_rule-header-small{
			width: 20%;
		}
		&_rule-sub-header{
			@extend %fare-rule-text;
			@extend %fare-rule-header;
			box-sizing: border-box;
			width: 40%;
		}
		&_rule-sub-row{
			@extend %fare-rule-text;
			@extend %fare-rule-header;
			box-sizing: border-box;
			width: 100%;

			&_link{
				color: lighten($brand-color-1,20);
				text-transform: uppercase;
				font-weight: bold;
			}
		}
		&_rule-title{
			@extend %fare-rule-text;
			font-weight: bold;
			color: $brand-color-2;
		}
	}
	&__sub_row{
		text-transform: uppercase;
	}
}

.mini-fare-rule{
	width: 100%;	
	&__info{
		width: 100%;	
		&_text{
			color: $brand-color-2;
			font-family: $font-family-number;
		    font-size: 12px;
		}
		&_icon{
			color: $brand-color-1;
			font-size: 0.875rem;	
		}
	}
}
