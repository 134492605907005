.pnr-result {
  &__header {
    width: 100%;
    @include clearfix();
    padding: 0 15px;
    border-bottom: 1px solid lighten($brand-color-5, 20);
    &_status {
      float: left;
      text-transform: uppercase;
      &-confirmed {
        color: $confirmed-text-color;
        
      }
      &-waiting {
        color: $waiting-text-color;
      }
      &-cancelled {
        color: $cancelled-text-color;
      }
    }
    &_ticket-number {
    	color: $dark-gray;
    	font-family: $font-family-number;
    }
    &_additional-info-container {
      float: right;
      position: relative;
      text-transform: none;
    }
    &_additional-info-link {
      cursor: pointer;
      color: $brand-color-2;

    }
    &_additional-info-link:hover {
      text-decoration: none;
    }
    &_additional-info {
      width: 120px;
      padding: 2px;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      .popover-content {
        padding: 2px;
      }
    }
  }
    &__footer {
        border-top: 1px solid lighten($brand-color-5, 20);
        color: $brand-color-2;
        font-weight: 600;
        min-height: 1.875rem;
        padding-top: 0.5rem;
        padding-right: 1rem;
        text-align: right;
        &_checkbox {
            .checkbox__input-container {
                    vertical-align: text-top;
            }
        }
    }
}
.pnr-modal__content {
  margin-top: 70px;
  padding-left: $base-padding/2;
  width: 99%;
}
.pnr-flight-details {
  .search__detail_row {
    margin: 0;
    padding: 0.5rem 0;
  }
  padding-left: 0;
  padding-right: 0;
  .detail__flight {
    padding-top: 3px;
  }
  &__ticketed {
  	background:$ticketed-row-color;
  }
  &__cancelled {
  	background:$cancelled-row-color;
    color:$brand-color-5;
  }
}

.pnr-flight-details {
    padding-top: $base-padding/8;
    padding-bottom: $base-padding/8;
}
.pnr__result{
	&_select-ticketing-airline {
		float: left;
	}
	&_select-fare-type {
		float: left;
		padding-left: 2rem;
	}
	    &_book {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: 0px;
	  float: right;
      &-button {
        @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
        @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
}

.air-select-airline{
	padding: .2rem 1rem;
	margin-left:.5rem;
}

.pnr-import-modal {
	text-align: center;
	&__text {
		color: $brand-color-2;
		padding-bottom: $base-padding/3;
		font-size: 18px;
	}
	&__button {
		width: 4.5rem;
		padding: $base-padding/5;
		background-color: $brand-color-3;
		border: none;
		font-size: 1rem;
		color: $brand-color-2;
		text-transform: uppercase;
		cursor: pointer;
	}
}

.modal-open {
	.pnr-import-modal.modal {
		overflow-x: hidden;
		overflow-y: hidden;
		width: 40%;
		margin-left: 32%;
		margin-top: 10%;
	}
}

.pnr-search-results {
	&__header {
 		background-color: $brand-color-3;
     	display: inline-block;
     	padding: $base-padding/5;
     	color: $brand-color-2;
     	margin-bottom: 0.5rem;
     	cursor: pointer;
	}
	 	
 	&__alternative-search-result{
 		background-color : $light-gray;
 		color : $dark ;
 		cursor: pointer;
 	}
 	
 	&__alternative-result-error{
 		background-color: $light;
 	 	box-shadow: $row-shadow-color;
 	 	@include create-review-nav;
     	text-align : center ; 
	 	padding-top : 0.5rem ;
	 	padding-bottom: 0.5rem;
    	margin-bottom: 0.5rem;	
 	}
 }
 
 
 .form-data {
 	    padding-bottom: 2.5rem;
 }
 
.pnr-fare-selection {
	&__container {
		&_error {
			text-align: center;
			.error {
				&__message {
					padding: 0.5rem 1rem;
				}
			}
		}
	}
}	
