.air__search_results {

  .two-pane-search__result {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    &_card {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.13);
      margin-bottom: 0;
      position: relative;
    }
    &_detail {
      box-shadow: $row-shadow-color;
      margin-bottom: 1%;
    }
	&_header{
	text-transform:uppercase;
	color:$brand-color-2;
	border-bottom: 1px solid darken($brand-color-4,20);
	padding-top: $base-padding/5;
	padding-bottom: $base-padding/5;
	padding-left: $base-padding/3;
    padding-right: $base-padding/3;		
    margin-bottom: $base-margin/5;
    margin-left: -$base-margin/2;
    margin-right: -15px;
	}

	&_card .recommended-product {
        left:0;
        border-width: 30px 30px 0px 0px;
    }

	&_card .recommended-product span {
    	top: -29px;
    	font-size:15px;
    }
	
	&_row{
		float:left;
	}
    &_flight,
    &_price {
      @include make-one-sixth;
      width:28.66667%
    }
    &_button{
    	width:5%;
    }
    &_radiobutton{
    	@include make-one-sixth;
    	padding-top: 1rem;
    	padding-left: 15px;
    }
    &_checkbox{
    	@include make-one-sixth;
    	padding-top: 1rem;
    	padding-left: 0;
    }
    &_flight {
    }
    &_price {
 		padding-left:10px;
        padding-right:10px;
    }
    
    &_trip {
    	@include make-one-sixth;
    	padding-left: 0;
    	padding-right: 0;
    	border-right: 1px solid;
      border-left: 1px solid;
      border-color: $row-flight-border-color;
      width:56%;
    }
    &_from,
    &_to,
    &_duration {
    	@include make-one-third;
    	width:49.33333%;
    }
    
    &_flexi-info-icon {
    	font-size: 1rem;
    	color: $brand-color-2;
    	padding-left: 1.4rem;
	}
    
    &_flexi-img{
    
    	max-width: 15px;
   	 	max-height: 15px;
    }
  }
  
  .two-pane-search__detail{
  
  	&_row{
  		float:left;
  		width: 108%;
  	}
  	&_flight,
    &_price {
      @include make-one-sixth;
      padding-top: 0.7rem;
      padding-right: 0.0rem;
      width: 16.66667%;
    }
    &_button{
    	width:5%;
    }
    &_radiobutton{
    	@include make-one-sixth;
    	padding-top: 2.9375rem;
    }
    &_air{
    	@include create-search-form;
    }
    
  }

  .two-pane-air__detail{
  
  	@include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
	position: relative;
    &_radiobutton,&_checkbox{
		position: absolute;
		top: 3rem;
	}
	&_radiobutton{
		left: 10px;
	}
	&_checkbox{
      	right:10px;
	}
    &_card {
      margin-bottom: 1%;
	  padding: 1rem;
       
       .two-pane-row-data{
 		width: 100%;
 			&__airline-info{
 				width: 22%;
 				float: left;
 			    text-transform: capitalize;
 			    max-height: 1.125rem;
				&_img {
						.icon {
							height: 1.5rem;
						}
					}
 			}
 			
 			&__amount{
 				width:30%;
 			    padding-top: 0.3125rem;
 			    float: left;
 				&_currency{
 					float: left;
 				    padding-right: 0.3125rem;
 				    padding-top: 0.375rem;
 				}
 				&_total-price, &_total-price-with-adhoc{
 					font-size: 1.125rem;
 					color: $brand-color-1;
 					font-family: $font-family-number;
 				}
 				&_total-price-with-adhoc{
 					color: $brand-color-14;
 				}
				&_offer-price{
					text-align: center;
					.offer-price{
						&__title{
							color: $brand-color-2;
							text-transform: capitalize;
						}
						&__amount{
							color: $brand-color-1;
						}
					}
				}
 			}
			&__loader{
				width:30%;
 			    padding-top: 0.3125rem;
 			    float: left;
				&_msg {
					font-size: 0.68rem;
				}
			}
			&__fetch-fare-button {
				width: 100%;
				margin-top: 0.3rem;
				margin-bottom: 0.3rem;
				padding: 0;
			}
 			
 			&__layover-time{
 				width: 20%;
 				padding-top: 0.5625rem;
 				float: left;
 				&_time-details{
 					color: $brand-color-8;
					 padding-left: 0.5rem;
					 font-family: $font-family-number;
 				}
 				&_stops{
 					font-size: 0.625rem;
 					.one-stop{
 						padding-left:0.25rem;
 					}
 					.multi-stop{
 						padding-left:0.0625rem;
 					}
 				}
 				&_aircraft{
 					font-size: 0.585rem;
 				}
 			}
 			
 			&__departure-details,&__arrival-details{
 				width:14%;
 				padding-top: 0.3125rem;
 				float: left;
 				&_time{
 					font-size: 1rem;
 					font-family: $font-family-number;
 					color: $brand-color-2;
 				}
				&_date{
 					font-size: 0.595rem;
 					font-weight: 700;
 					font-family: $font-family-number;
 				}
			 }
			 &__arrival-details{
				 &_time{
					 display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: flex-end;
					.day-diff{
						    color: #ec2227;
    						font-size: .563rem;
   							line-height: .625rem;
    						padding-bottom: 0.313rem;
    						padding-left: 0.313rem;
    						text-align: left;
    						width: 45%;
					}
				 }
			 }
			 
			 &__llf-info{
				font-size: $font-size-h6;
				float: right;
				color: $brand-color-2;
				.llf-info{
					&__button{
						background-color: $light;
					}
				}
			  }
       }
    }
  	&_row{
  		float:left;
  		width: 100%;
  		box-shadow : 0 0 15px rgba(0,0,0,.25);
  		height: 85px;
    	padding-top: 0.7rem;
		margin-left:0;
		background-color: $light;
  	}
  	&_flight_onwardleg{
      @include make-one-sixth;
      padding-top: 0;
      height: 48px;
      padding-left: 0.0rem;
      padding-right: 0rem;
      &-image{
		max-width: 30px;
		max-height: 30px;
      }
    }
  	&_flight_returnleg{
      @include make-one-sixth;
      padding-top: 0;
      width:15%;
      height: 45px;
    }
    &_onwardleg{
       @include make-one-sixth;
       padding-left: 0.4rem;
       padding-right: 0.4rem;
       float: left;
       height:60px;
	   width: 25%;
    }
    &_returnleg{
       float: left;
       width: 43%;
    }
    &_flight-totalfare{
	    @include make-one-sixth;
    	width: 100%;
		.flight-totalfare {
			&__currency {
				padding-top: 0.375rem;
			}
			&__amount{
				color: $brand-color-1;
				font-family: $font-family-number;
				font-size: 1rem;
			}
		}
	}
    &_leg{
    	@include make-four-fifth;
    }
    &_book{
    	@include make-one-fifth;
    }
    &_flight_fzimage{
    	width:93%;
    }
    &_data{
    	@include make-one-sixth;
    	width: 80%;
    	padding-right:0rem;
    }
  }
  
  .two-pane__wrapper{
  		@include clearfix;
  		position: relative;
  		
  	&_book-container{
  		float: left;
    	width: 100%;
  	}
  	&_data-content{
  		float: left;
  	}
  		&_search-details{
   			width:100%;
   			.search-result-details{
   				width:100%;
   				float:left;
   				padding: 1rem;
   				padding-bottom:0;
   				padding-top:0.4375rem;
     			margin-bottom: 0.3125rem;
   				&__flight-icon{
   					float:left;
 				    font-size: 1.375rem;
     				padding-right: 0.625rem;
 				    padding-top: 0.1875rem;
   				}
   				&__search-data{
   					&_from-city,&_to-city{
   						color: $brand-color-8;
   						font-size: 0.75rem;
   					}
   				}
	
   			}
   		}
  	
  	&_content{
	  	float:left;
	  	width:100%;
	  	border-right: 1px solid #ACEDDF;
  	}
  	&_title{
  		font-size: 0.9rem;
    	font-weight: bolder;
    	background-color: lightgray;
  	}
  	&_title_active{
  		font-size: 0.9rem;
    	font-weight: bolder;
    	background-color: $brand-color-2;
    	text-align: center;
    	color: white; 
    	.filter__content { 
    		&_right {
    			.right__arrow-icon {
    				color: $brand-color-2; 
    			}
    		}
    		&_left {
    			.left__arrow-icon {
    				color: $brand-color-2;
    			}
    		}
    	
    	}
  	}
  	&_title_inactive{
  		font-size: 0.9rem;
    	font-weight: bolder;
    	background-color: lightgray;
    	text-align: center;
  	}
  	&_sort{
  		li{
  			width:28%;
  		}
  	}
  }
  
  .fa{
  //	color: whitesmoke;
  }
.two-pane-flight-details {
  @include create-flight-detail-row;
  	
	&__tab-content {
		height: 6.875rem;
	}
	
	&__tab-footer{
   		background-color: $brand-color-4;
 	    @include clearfix;
   		width: 100%;
   		&_flight-details{
   	    	.flight-details-button{
     		    padding-top: .3125rem;
 			    padding-bottom: .3125rem;
 			    display: block;
 			    text-align: center;
 			    color: $dark;
 			    float: left;
 			    cursor: pointer;
 			    width: 20%;
 			    text-transform: uppercase;
 			    font-size: .625rem;
 			    font-weight: 600;
		        background-color: $brand-color-3;
   	    	}
   	    }
   	    
   	    &_extra-details{
    	    float: right;
		    padding: .3125rem;
		    min-height: .0625rem;
		    width: 45%;
		    text-align: right;
		    text-transform: uppercase;
		    font-size: .625rem;
		    font-weight: 600;
   	    }
   	    
   	    &_supplier-details{
   	    	width: 35%;
    		text-overflow: ellipsis;
    		float: left;
    		overflow: hidden;
    		text-align: left;
    		padding: 0.125rem 0.625rem;
    		padding-top: 0.125rem;
   	    }
   	}
  
	&__airline {
	  font-size: $font-size-h5;
	}
	&__iata{
		font-size: $font-size-h6;
	 	width: 85%;
	}
	&__from,
	&__to {
	  	&_time {
	       width: 400%;
	       font-size: 0.9rem;
	    }
	    &_departure{
	    	padding-right: 15%;
 	        font-size: 0.9rem;
  		}
	}
	&__duration {
		width:18%;
		float:left;
		padding-top: 0%;
	    &_wrapper {
	    	width: 36%;
	    }
	    &_time {
	      color: $brand-color-2;
	      position: '';
	      text-transform: '';
	      text-align: left;
	      font-weight: 600;
	      font-size: 0.9rem;
	      &:before,
      	  &:after {
            width: 0px;
       		height: 0px;
      	  }
	    }
 	}
	  
  }	
  
  .filter__content{
	  &_wrapper{
	  	@include clearfix;
	  	width:100%;
	  	overflow:hidden;
	  	position:relative;
	  }
	  &_child{
	  	@include clearfix;
	  	position:relative;
	  	left:0%;
	  }
	  &_pane{
	  	@include clearfix;
    	float: left;
   	    @include make-responsive-width('width',50%);
	  }
	  &_left{
		position: absolute;
	    left: 10px;
	    padding-left: 2rem;
	    cursor: pointer;
	    font-size: 1.5rem;
	    padding: 3px;
	    .left {
	    	&__circle-icon {
	    		color:$light;
	    	}
	    	&__arrow-icon {
	    		color:$brand-color-5;
	    	}
	    }
	  }
	  &_right{
	    cursor: pointer;
	    font-size: 1.5rem;
	    padding: 3px;
	    float: right;
	    margin-right: 0.65rem;
	    .right {
	    	&__circle-icon {
	    		color:$light;
	    	}
	    	&__arrow-icon {
	    		color:$brand-color-5;
	    	}
	    }
	  }
	  &_header{
		margin-top : 0.125rem;
		cursor:pointer;
	  }
	  &_tabs{
	  	height: 35px;
	  }
	  &_routes{
  	    padding-top: 8px;
    	padding-left: 35px;
	  }
  }

  .two-pane-detail{
  	&__flight{
  		&_fzimage{
  			width: 115%;
  		}
  		&_image{
  			max-width: 30px;
    		max-height: 30px;
  		}
  	}
  	&__seat_count_red{
	  	color: red;
	  	font-size: 11px;
  		font-weight: bold;
	 }
	 &__seat_count_green{
	  	color: green;
	  	font-size: 11px;
 		font-weight: bold;
	 }
	 &__seat_text{
	 	color: $brand-color-2;
	 }
  }
  
  .two-pane-details{
  	
  	&__flight{
  		max-width: 28px;
    	max-height: 30px;
  	}
  	&_from,
  	&_duration{
  		padding-top: 0.7rem;
  	}
  	&_duration_from{
  		padding-top: 0rem;
  		width: 50%;
	    padding-left: .9375rem;
	    padding-right: .9375rem;
	    float: left;
	    min-height: 1px;
	    padding-bottom: 0.2rem;
  	}
  	&__filter_activetab{
		color: $light;
		background-color: $brand-color-2;
		text-shadow: 0 1px 0 #000;
	    display: block;
	    padding: 4px 8px;
	    font-size: 13px;
	    font-weight: bold;
	    position: relative;
	    top: 1px;
	    outline: none;
	    box-shadow: 0px 1px 0px rgba(0,0,0,0.05) inset,0px 1px $light;
	    border-radius: 3px;
	    border: 1px solid #ccc;
	    float: left;
	    cursor : pointer;
  	}
  	&__filter_inactivetab{
	    display: block;
	    padding: 4px 8px;
	    color: #666;
	    font-size: 13px;
	    font-weight: bold;
	    position: relative;
	    top: 1px;
	    outline: none;
	    background-color: rgba(0,0,0,0.1);
	    box-shadow: 0px 1px 0px rgba(0,0,0,0.05) inset,0px 1px $light;
	    border-radius: 3px;
	    border: 1px solid #ccc;
	    float: left;
	    cursor : pointer;
  	}
  	&__filter_result_active{
  		color: $light;
		background-color: $brand-color-2;
		text-shadow: 0 1px 0 #000;
	    padding: 2px 8px;
	    color: #666;
	    font-size: 13px;
	    font-weight: bold;
	    outline: none;
	    box-shadow: 0px 1px 0px rgba(0,0,0,0.05) inset,0px 1px $light;
	    border-radius: 3px;
	    border: 1px solid #ccc;
  	}
  	&__filter_result_inactive{
	    padding: 2px 8px;
	    color: #666;
	    font-size: 13px;
	    font-weight: bold;
	    outline: none;
	    background-color: rgba(0,0,0,0.1);
	    box-shadow: 0px 1px 0px rgba(0,0,0,0.05) inset,0px 1px $light;
	    border-radius: 3px;
	    border: 1px solid #ccc;
  	}
  	
  }
  
  .two-pane-result {
  	&__faretype{
  		overflow: hidden;
    	text-overflow: ellipsis;
  		&_value{
  			font-size: $font-size-h7;
  		}
  	}	
    &__fname {
      text-transform: capitalize;
    }
    &__flight,
    &__from,
    &__to,
    &__duration,
    &__price {
      padding-top: 0.7rem;

      // padding-bottom: $base-padding/2;
     &_llf-info{
        font-size: $font-size-h6;
        float: right;
    	color: $brand-color-2;
    	.llf-info{
        	&__button{
        		background-color: $light;
        	}
        }
      }
    }
    &__flight,
    &__duration {
      text-align: center;
    }
    &__flight {
   	 &_image {
    	max-width: 28px;
    	max-height: 30px;
  	  }
    }
    &__from {
      text-align: left;
    }
    &__to {
      text-align: right;
    }
    &__from,
    &__to {
      &_time,
      &_date {
        font-family: $font-family-number;
        font-size: 0.75rem;
		font-weight: bold;
      }
      &_time {
        font-size: $font-size-number;
        color: $row-time-color;
        font-weight: 400;
      }
    }
    &__duration {
      &_time {
        font-size: $font-size-number;
        font-family: $font-family-number;
        font-weight: 400;
        color: $row-duration-color;
        text-transform: lowercase;
      }
      &_type {
        position: relative;
        &:before,
        &:after {
          width: 80px;
          content: "";
          height: 1px;
          background-color: lighten($brand-color-5, 30);
          position: absolute;
          top: 50%;
        }
        &:before {
          margin-right: -20px;
          right: 100%;
        }
        &:after {
          margin-left: -20px;
          left: 100%;
        }
      }
      &_legs {
        font-size: $font-size-h6;
      }
    }
    &__price {
     padding-top: 0.7rem;
     &_llf-info{
        font-size: $font-size-h6;
        float: right;
    	color: $brand-color-2;
    	.llf-info{
        	&__button{
        		background-color: $light;
        	}
        }
      }
      &_currency {

      }
      &_amount,&_amount-with-adhoc {
        font-size: $font-size-h3 - 0.325;
        font-family: $font-family-number;
		color: $brand-color-1;
        // font-weight: 400;
      }
      &_amount-with-adhoc{
	    	color : $brand-color-14;
      }
      &_adhoc-amount{
   	  	display: inline-block;
   	  	width: 100%;
    	text-align: center;
      }
    }
    &__flight_details_button,
    &__extra_details_text {
      text-transform: uppercase;
      font-size: $font-size-h6;
      font-weight: 600;
    }
    &__flight_details {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      &_button {
        width: 100%;
        background-color: $row-flight-details-button-bgcolor;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        display: block;
        text-align: center;
        color: $dark;
        &:hover,
        &:active,
        &:focus {
          color: $row-flight-details-button-state;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__extra_details {
      @include make-three-fifth;
      padding-top: $base-padding/6;
      padding-bottom: $base-padding/6;
      background-color: $row-footer-bgcolor;
      border-right: 1px solid;
      border-left: 1px solid;
      border-color: $row-flight-details-border-color;
      &_text {
        float: right;
      }
	  .cabin-class-downgrade-icon{
	  				color: $brand-color-37;
	  							  					font-size: .825rem;
	  							  					text-align: right;
	  							  					text-transform: uppercase;
	  							  					padding: 0rem 0.25rem;
	  			}
    }
    &__book {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      padding-top: 0.4rem;
      width: 80%;
      &_button {
        @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
        @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__search_flight {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      float:right;
      &_button {
        @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
        @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

.filter__section {
	.pane__section {
		@include clearfix;

		&_checkbox {
			float: left;
			width: 15%;
		}

		&_pane-label {
			float: left;
			width: 50%;
			padding-top: ($base-padding/5);
			@include clearfix;
		}

		&_only-pane-selection {
			float: right;
			cursor: pointer;
			color: $brand-color-2;
			padding-left: ($base-padding/5);
			font-weight: 400;
			text-transform: capitalize;
		}

		&_notification{
			float: left;
			color: $brand-color-2;
			padding-top: ($base-padding/5);
			font: 0.625rem;
			width: 100%;
		}
	}
}
}
.air-port-message-info-icon {
  font-size: 1.4rem;
  color: #ec2227;
  padding-left: 0.5rem;
}
